import pikaday from 'pikaday';

(function () {
    function dateValid(date) {
        const d = new Date(date[2], date[1] - 1, date[0]);
        return d && d.getFullYear() == date[2] && d.getMonth() + 1 == date[1] && d.getDate() == date[0];
    }

    function pad(n, width, z) {
        z = z || '0';
        n += '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    const dayNames = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const dayNamesShort = ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'];
    const monthNames = [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
    ];

    function updateSpans(picker, date) {
        const d = new Date(date[2], date[1] - 1, date[0]);

        picker.find("[data-selector='dayName']").text(dayNames[d.getDay()]);
        picker.find("[data-selector='day']").text(d.getDate());
        picker.find("[data-selector='monthName']").text(monthNames[d.getMonth()]);
        picker.find("[data-selector='year']").text(d.getFullYear());
    }

    function updateInputs(picker, date) {
        const d = new Date(date[2], date[1] - 1, date[0]);

        picker.find("input[data-selector='day']").val(d.getDate());
        picker.find("input[data-selector='month']").val(d.getMonth() + 1);
        picker.find("input[data-selector='year']").val(d.getFullYear());
    }

    function template(date, id) {
        const day = date.getDate();
        const dayName = dayNames[date.getDay()];
        const month = date.getMonth() + 1;
        const monthName = monthNames[date.getMonth()];
        const year = date.getFullYear();

        if (!id) id = _.uniqueId('datepicker');

        return (
            `<div class="datepicker" id="${id}">` +
            '<div class="datepicker-inputs">' +
            `<input class="date-input form-control" type="text" data-validate-ignore autocomplete="off" data-selector="day" placeholder="${day}"/><i>/</i>` +
            `<input class="date-input form-control" type="text" data-validate-ignore autocomplete="off" data-selector="month" placeholder="${month}"/><i>/</i>` +
            `<input class="date-input form-control large" type="text" data-validate-ignore autocomplete="off" data-selector="year" placeholder="${year}"/>` +
            '</div>' +
            "<button type='button' class='btn btn-calendar' data-selector='datepicker-button'><i class='icon-calendar'></i></button>" +
            '<span class="datepicker-result">' +
            `<span data-selector="dayName" class="date-label">${dayName}</span> ` +
            `<span data-selector="day" class="date-label">${day}</span> ` +
            `<span data-selector="monthName" class="date-label">${monthName}</span>, ` +
            `<span data-selector="year" class="date-label">${year}</span>` +
            '</span>' +
            '</div>'
        );
    }

    function getInputs(picker) {
        const dayInp = picker.find("input[data-selector='day']");
        const monthInp = picker.find("input[data-selector='month']");
        const yearInp = picker.find("input[data-selector='year']");

        let found = false;
        let x = 0;
        let monthRaw = monthInp.val();
        if (monthRaw.length > 0) {
            for (let i = 0; i < 12; i++) {
                if (monthNames[i].indexOf(monthRaw) === 0) {
                    if (found) {
                        found = false; // not unique, so not found
                        break;
                    }

                    found = true;
                    x = i + 1;
                }
            }
        }
        if (found) monthRaw = x;

        const dayVal = parseInt(dayInp.val() || dayInp.attr('placeholder'));
        const monthVal = parseInt(monthRaw || monthInp.attr('placeholder'));
        let yearVal = parseInt(yearInp.val() || yearInp.attr('placeholder'));

        yearVal = yearVal < 100 ? yearVal + 2000 : yearVal;

        return [dayVal, monthVal, yearVal];
    }

    function updateOriginalInput($originalinput, date) {
        const year = date[2];
        const month = date[1] < 10 ? `0${date[1]}` : date[1];
        const day = date[0] < 10 ? `0${date[0]}` : date[0];

        $originalinput.val(`${year}-${month}-${day}`);
        $originalinput.change();
    }

    $.fn.datepicker = function (initDate) {
        // initDate should be of format yyyy-MM-dd (ISO8601)
        const $originalinput = $(this);
        const specifiedDate = $originalinput.val() ? new Date($originalinput.val()) : null;
        const initialDate = initDate || specifiedDate || new Date();

        const picker = $(template(initialDate, $originalinput.data('id')));
        $originalinput.after(picker);
        $originalinput.hide();

        picker.find("[data-selector='datepicker-button']").each((ix, elem) => {
            const p = new pikaday({
                onSelect(date) {
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();

                    updateOriginalInput($originalinput, [day, month, year]);
                    updateSpans(picker, [day, month, year]);
                    updateInputs(picker, [day, month, year]);

                    $(this.el).hide();
                },
                i18n: {
                    previousMonth: 'Vorige maand',
                    nextMonth: 'Volgende maand',
                    months: monthNames,
                    weekdays: dayNames,
                    weekdaysShort: dayNamesShort,
                },
                keyboardInput: false,
            });
            elem.parentNode.insertBefore(p.el, elem.nextSibling);

            $(p.el).hide();

            $(elem).click(() => {
                $(p.el).toggle();
            });
        });

        const date = getInputs(picker);
        updateOriginalInput($originalinput, date);

        picker
            .find("input[data-selector='day']")
            .change(function () {
                const $this = $(this);

                const date = getInputs(picker);
                if (!dateValid(date) || !/^[0-9]+$/.test($this.val())) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    updateSpans(picker, date);
                    updateOriginalInput($originalinput, date);
                    $this.val(date[0]);
                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const date = getInputs(picker);
                const curVal = date[0];
                const maxVal = new Date(date[2], date[1], 0).getDate();
                if (key == 38) {
                    // up arrow
                    $this.val(curVal + 1 > maxVal ? maxVal : curVal + 1);
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    $this.val(curVal - 1 < 1 ? 1 : curVal - 1);
                    $this.change();
                }
            });

        picker
            .find("input[data-selector='month']")
            .change(function () {
                const $this = $(this);

                const date = getInputs(picker);
                if (!dateValid(date)) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    updateSpans(picker, date);
                    updateOriginalInput($originalinput, date);
                    $this.val(date[1]);
                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const curVal = getInputs(picker)[1];
                if (key == 38) {
                    // up arrow
                    $this.val(curVal + 1 > 12 ? 12 : curVal + 1);
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    $this.val(curVal - 1 < 1 ? 1 : curVal - 1);
                    $this.change();
                }
            });

        // inp is the input object, yr is a two-digit year value
        function setYearVal(inp, yr) {
            inp.val(pad(yr, 2));
        }

        picker
            .find("input[data-selector='year']")
            .change(function () {
                const $this = $(this);

                const date = getInputs(picker);
                if (!dateValid(date) || !/^[0-9]+$/.test($this.val())) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    updateSpans(picker, date);
                    setYearVal($this, date[2] - 2000);
                    updateOriginalInput($originalinput, date);
                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const curVal = getInputs(picker)[2] - 2000;
                if (key == 38) {
                    // up arrow
                    setYearVal($this, curVal + 1);
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    setYearVal($this, curVal - 1);
                    $this.change();
                }
            });

        return function (day, month, year) {
            updateSpans(picker, [day, month, year]);
            updateInputs(picker, [day, month, year]);
        };
    };
})();
