module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var id = _.uniqueId("aanvraagfile"); 
__p+='\r\n<div class="file-img">\r\n    <img data-selector="thumbnail" src="'+
((__t=( ThumbnailUrl == null ? IconUrl : ThumbnailUrl ))==null?'':__t)+
'"/>\r\n</div>\r\n<div class="file-content">\r\n    <div class="file-title">\r\n        <input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].DownloadUrl" value="'+
((__t=( DownloadUrl ))==null?'':_.escape(__t))+
'" data-inputfor="DownloadUrl" />\r\n        <input type="text" name="Title" value="'+
((__t=( Title ))==null?'':__t)+
'" class="form-control" data-inputfor="Title" />\r\n    </div>\r\n    <div class="file-description">\r\n        <textarea name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].Description" class="form-control" data-inputfor="Description"/>'+
((__t=( Description ))==null?'':__t)+
'</textarea>\r\n    </div>\r\n    <div class="file-actions-pending">\r\n        <progress class="progress file-progress" value="0" max="100">0%</progress>\r\n        <button class="btn btn-link inline-edit" data-selector="save-file-button" style="display: none">Opslaan</button>\r\n    </div>\r\n</div>';
}
return __p;
};
