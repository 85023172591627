export default {
    initial() {
        window.onload = function () {
            window.isDirty = false;
            window.submitting = false;

            window.addEventListener('beforeunload', (e) => {
                if (submitting || !window.isDirty) {
                    return undefined;
                }

                const confirmationMessage = 'Als u dit venster sluit, worden de wijzigingen niet opgeslagen.';

                (e || window.event).returnValue = confirmationMessage; // Gecko + IE
                return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
            });
        };

        window.stopPreventLeave = function () {
            window.isDirty = false;
        };

        window.startPreventLeave = function (formSelector) {
            window.isDirty = true;
            $(formSelector || '[data-finalform]').submit(() => {
                window.submitting = true;
            });
        };
    },
};
