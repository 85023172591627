import jQuery from 'jquery';

/* ========================================================================
 * Bootstrap: tooltip.js v3.3.6
 * http://getbootstrap.com/javascript/#tooltip
 * Inspired by the original jQuery.tipsy by Jason Frame
 * ========================================================================
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ======================================================================== */

+(function ($) {
    // TOOLTIP PUBLIC CLASS DEFINITION
    // ===============================

    const Tooltip = function (element, options) {
        this.type = null;
        this.options = null;
        this.enabled = null;
        this.timeout = null;
        this.hoverState = null;
        this.$element = null;
        this.inState = null;

        this.init('tooltip', element, options);
    };

    Tooltip.VERSION = '3.3.6';

    Tooltip.TRANSITION_DURATION = 150;

    Tooltip.DEFAULTS = {
        animation: true,
        placement: 'top',
        selector: false,
        template:
            '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
        trigger: 'hover focus',
        title: '',
        delay: 0,
        html: false,
        container: false,
        viewport: {
            selector: 'body',
            padding: 0,
        },
    };

    Tooltip.prototype.init = function (type, element, options) {
        this.enabled = true;
        this.type = type;
        this.$element = $(element);
        this.options = this.getOptions(options);
        this.$viewport =
            this.options.viewport &&
            $(
                $.isFunction(this.options.viewport)
                    ? this.options.viewport.call(this, this.$element)
                    : this.options.viewport.selector || this.options.viewport,
            );
        this.inState = {click: false, hover: false, focus: false};

        if (this.$element[0] instanceof document.constructor && !this.options.selector) {
            throw new Error(
                `\`selector\` option must be specified when initializing ${this.type} on the window.document object!`,
            );
        }

        const triggers = this.options.trigger.split(' ');

        for (let i = triggers.length; i--; ) {
            const trigger = triggers[i];

            if (trigger == 'click') {
                this.$element.on(`click.${this.type}`, this.options.selector, $.proxy(this.toggle, this));
            } else if (trigger != 'manual') {
                const eventIn = trigger == 'hover' ? 'mouseenter' : 'focusin';
                const eventOut = trigger == 'hover' ? 'mouseleave' : 'focusout';

                this.$element.on(`${eventIn}.${this.type}`, this.options.selector, $.proxy(this.enter, this));
                this.$element.on(`${eventOut}.${this.type}`, this.options.selector, $.proxy(this.leave, this));
            }
        }

        this.options.selector
            ? (this._options = $.extend({}, this.options, {
                  trigger: 'manual',
                  selector: '',
              }))
            : this.fixTitle();
    };

    Tooltip.prototype.getDefaults = function () {
        return Tooltip.DEFAULTS;
    };

    Tooltip.prototype.getOptions = function (options) {
        options = $.extend({}, this.getDefaults(), this.$element.data(), options);

        if (options.delay && typeof options.delay === 'number') {
            options.delay = {
                show: options.delay,
                hide: options.delay,
            };
        }

        return options;
    };

    Tooltip.prototype.getDelegateOptions = function () {
        const options = {};
        const defaults = this.getDefaults();

        this._options &&
            $.each(this._options, (key, value) => {
                if (defaults[key] != value) options[key] = value;
            });

        return options;
    };

    Tooltip.prototype.enter = function (obj) {
        let self = obj instanceof this.constructor ? obj : $(obj.currentTarget).data(`bs.${this.type}`);

        if (!self) {
            self = new this.constructor(obj.currentTarget, this.getDelegateOptions());
            $(obj.currentTarget).data(`bs.${this.type}`, self);
        }

        if (obj instanceof $.Event) {
            self.inState[obj.type == 'focusin' ? 'focus' : 'hover'] = true;
        }

        if (self.tip().hasClass('in') || self.hoverState == 'in') {
            self.hoverState = 'in';
            return;
        }

        clearTimeout(self.timeout);

        self.hoverState = 'in';

        if (!self.options.delay || !self.options.delay.show) return self.show();

        self.timeout = setTimeout(() => {
            if (self.hoverState == 'in') self.show();
        }, self.options.delay.show);
    };

    Tooltip.prototype.isInStateTrue = function () {
        for (const key in this.inState) {
            if (this.inState[key]) return true;
        }

        return false;
    };

    Tooltip.prototype.leave = function (obj) {
        let self = obj instanceof this.constructor ? obj : $(obj.currentTarget).data(`bs.${this.type}`);

        if (!self) {
            self = new this.constructor(obj.currentTarget, this.getDelegateOptions());
            $(obj.currentTarget).data(`bs.${this.type}`, self);
        }

        if (obj instanceof $.Event) {
            self.inState[obj.type == 'focusout' ? 'focus' : 'hover'] = false;
        }

        if (self.isInStateTrue()) return;

        clearTimeout(self.timeout);

        self.hoverState = 'out';

        if (!self.options.delay || !self.options.delay.hide) return self.hide();

        self.timeout = setTimeout(() => {
            if (self.hoverState == 'out') self.hide();
        }, self.options.delay.hide);
    };

    Tooltip.prototype.show = function () {
        const e = $.Event(`show.bs.${this.type}`);

        if (this.hasContent() && this.enabled) {
            this.$element.trigger(e);

            const inDom = $.contains(this.$element[0].ownerDocument.documentElement, this.$element[0]);
            if (e.isDefaultPrevented() || !inDom) return;
            const that = this;

            const $tip = this.tip();

            const tipId = this.getUID(this.type);

            this.setContent();
            $tip.attr('id', tipId);
            this.$element.attr('aria-describedby', tipId);

            if (this.options.animation) $tip.addClass('fade');

            let placement =
                typeof this.options.placement === 'function'
                    ? this.options.placement.call(this, $tip[0], this.$element[0])
                    : this.options.placement;

            const autoToken = /\s?auto?\s?/i;
            const autoPlace = autoToken.test(placement);
            if (autoPlace) placement = placement.replace(autoToken, '') || 'top';

            $tip.detach().css({top: 0, left: 0, display: 'block'}).addClass(placement).data(`bs.${this.type}`, this);

            this.options.container ? $tip.appendTo(this.options.container) : $tip.insertAfter(this.$element);
            this.$element.trigger(`inserted.bs.${this.type}`);

            const pos = this.getPosition();
            const actualWidth = $tip[0].offsetWidth;
            const actualHeight = $tip[0].offsetHeight;

            if (autoPlace) {
                const orgPlacement = placement;
                const viewportDim = this.getPosition(this.$viewport);

                placement =
                    placement == 'bottom' && pos.bottom + actualHeight > viewportDim.bottom
                        ? 'top'
                        : placement == 'top' && pos.top - actualHeight < viewportDim.top
                        ? 'bottom'
                        : placement == 'right' && pos.right + actualWidth > viewportDim.width
                        ? 'left'
                        : placement == 'left' && pos.left - actualWidth < viewportDim.left
                        ? 'right'
                        : placement;

                $tip.removeClass(orgPlacement).addClass(placement);
            }

            const calculatedOffset = this.getCalculatedOffset(placement, pos, actualWidth, actualHeight);

            this.applyPlacement(calculatedOffset, placement);

            const complete = function () {
                const prevHoverState = that.hoverState;
                that.$element.trigger(`shown.bs.${that.type}`);
                that.hoverState = null;

                if (prevHoverState == 'out') that.leave(that);
            };

            $.support.transition && this.$tip.hasClass('fade')
                ? $tip.one('bsTransitionEnd', complete).emulateTransitionEnd(Tooltip.TRANSITION_DURATION)
                : complete();
        }
    };

    Tooltip.prototype.applyPlacement = function (offset, placement) {
        const $tip = this.tip();
        const width = $tip[0].offsetWidth;
        const height = $tip[0].offsetHeight;

        // manually read margins because getBoundingClientRect includes difference
        let marginTop = parseInt($tip.css('margin-top'), 10);
        let marginLeft = parseInt($tip.css('margin-left'), 10);

        // we must check for NaN for ie 8/9
        if (isNaN(marginTop)) marginTop = 0;
        if (isNaN(marginLeft)) marginLeft = 0;

        offset.top += marginTop;
        offset.left += marginLeft;

        // $.fn.offset doesn't round pixel values
        // so we use setOffset directly with our own function B-0
        $.offset.setOffset(
            $tip[0],
            $.extend(
                {
                    using(props) {
                        $tip.css({
                            top: Math.round(props.top),
                            left: Math.round(props.left),
                        });
                    },
                },
                offset,
            ),
            0,
        );

        $tip.addClass('in');

        // check to see if placing tip in new offset caused the tip to resize itself
        const actualWidth = $tip[0].offsetWidth;
        const actualHeight = $tip[0].offsetHeight;

        if (placement == 'top' && actualHeight != height) {
            offset.top = offset.top + height - actualHeight;
        }

        const delta = this.getViewportAdjustedDelta(placement, offset, actualWidth, actualHeight);

        if (delta.left) offset.left += delta.left;
        else offset.top += delta.top;

        const isVertical = /top|bottom/.test(placement);
        const arrowDelta = isVertical ? delta.left * 2 - width + actualWidth : delta.top * 2 - height + actualHeight;
        const arrowOffsetPosition = isVertical ? 'offsetWidth' : 'offsetHeight';

        $tip.offset(offset);
        this.replaceArrow(arrowDelta, $tip[0][arrowOffsetPosition], isVertical);
    };

    Tooltip.prototype.replaceArrow = function (delta, dimension, isVertical) {
        this.arrow()
            .css(isVertical ? 'left' : 'top', `${50 * (1 - delta / dimension)}%`)
            .css(isVertical ? 'top' : 'left', '');
    };

    Tooltip.prototype.setContent = function () {
        const $tip = this.tip();
        const title = this.getTitle();

        $tip.find('.tooltip-inner')[this.options.html ? 'html' : 'text'](title);
        $tip.removeClass('fade in top bottom left right');
    };

    Tooltip.prototype.hide = function (callback) {
        const that = this;
        const $tip = $(this.$tip);
        const e = $.Event(`hide.bs.${this.type}`);

        function complete() {
            if (that.hoverState != 'in') $tip.detach();
            that.$element.removeAttr('aria-describedby').trigger(`hidden.bs.${that.type}`);
            callback && callback();
        }

        this.$element.trigger(e);

        if (e.isDefaultPrevented()) return;

        $tip.removeClass('in');

        $.support.transition && $tip.hasClass('fade')
            ? $tip.one('bsTransitionEnd', complete).emulateTransitionEnd(Tooltip.TRANSITION_DURATION)
            : complete();

        this.hoverState = null;

        return this;
    };

    Tooltip.prototype.fixTitle = function () {
        const $e = this.$element;
        if ($e.attr('title') || typeof $e.attr('data-original-title') !== 'string') {
            $e.attr('data-original-title', $e.attr('title') || '').attr('title', '');
        }
    };

    Tooltip.prototype.hasContent = function () {
        return this.getTitle();
    };

    Tooltip.prototype.getPosition = function ($element) {
        $element = $element || this.$element;

        const el = $element[0];
        const isBody = el.tagName == 'BODY';

        let elRect = el.getBoundingClientRect();
        if (elRect.width == null) {
            // width and height are missing in IE8, so compute them manually; see https://github.com/twbs/bootstrap/issues/14093
            elRect = $.extend({}, elRect, {
                width: elRect.right - elRect.left,
                height: elRect.bottom - elRect.top,
            });
        }
        const elOffset = isBody ? {top: 0, left: 0} : $element.offset();
        const scroll = {
            scroll: isBody ? document.documentElement.scrollTop || document.body.scrollTop : $element.scrollTop(),
        };
        const outerDims = isBody ? {width: $(window).width(), height: $(window).height()} : null;

        return $.extend({}, elRect, scroll, outerDims, elOffset);
    };

    Tooltip.prototype.getCalculatedOffset = function (placement, pos, actualWidth, actualHeight) {
        return placement == 'bottom'
            ? {
                  top: pos.top + pos.height,
                  left: pos.left + pos.width / 2 - actualWidth / 2,
              }
            : placement == 'top'
            ? {
                  top: pos.top - actualHeight,
                  left: pos.left + pos.width / 2 - actualWidth / 2,
              }
            : placement == 'left'
            ? {
                  top: pos.top + pos.height / 2 - actualHeight / 2,
                  left: pos.left - actualWidth,
              }
            : /* placement == 'right' */ {
                  top: pos.top + pos.height / 2 - actualHeight / 2,
                  left: pos.left + pos.width,
              };
    };

    Tooltip.prototype.getViewportAdjustedDelta = function (placement, pos, actualWidth, actualHeight) {
        const delta = {top: 0, left: 0};
        if (!this.$viewport) return delta;

        const viewportPadding = (this.options.viewport && this.options.viewport.padding) || 0;
        const viewportDimensions = this.getPosition(this.$viewport);

        if (/right|left/.test(placement)) {
            const topEdgeOffset = pos.top - viewportPadding - viewportDimensions.scroll;
            const bottomEdgeOffset = pos.top + viewportPadding - viewportDimensions.scroll + actualHeight;
            if (topEdgeOffset < viewportDimensions.top) {
                // top overflow
                delta.top = viewportDimensions.top - topEdgeOffset;
            } else if (bottomEdgeOffset > viewportDimensions.top + viewportDimensions.height) {
                // bottom overflow
                delta.top = viewportDimensions.top + viewportDimensions.height - bottomEdgeOffset;
            }
        } else {
            const leftEdgeOffset = pos.left - viewportPadding;
            const rightEdgeOffset = pos.left + viewportPadding + actualWidth;
            if (leftEdgeOffset < viewportDimensions.left) {
                // left overflow
                delta.left = viewportDimensions.left - leftEdgeOffset;
            } else if (rightEdgeOffset > viewportDimensions.right) {
                // right overflow
                delta.left = viewportDimensions.left + viewportDimensions.width - rightEdgeOffset;
            }
        }

        return delta;
    };

    Tooltip.prototype.getTitle = function () {
        let title;
        const $e = this.$element;
        const o = this.options;

        title = $e.attr('data-original-title') || (typeof o.title === 'function' ? o.title.call($e[0]) : o.title);

        return title;
    };

    Tooltip.prototype.getUID = function (prefix) {
        do prefix += ~~(Math.random() * 1000000);
        while (document.getElementById(prefix));
        return prefix;
    };

    Tooltip.prototype.tip = function () {
        if (!this.$tip) {
            this.$tip = $(this.options.template);
            if (this.$tip.length != 1) {
                throw new Error(`${this.type} \`template\` option must consist of exactly 1 top-level element!`);
            }
        }
        return this.$tip;
    };

    Tooltip.prototype.arrow = function () {
        return (this.$arrow = this.$arrow || this.tip().find('.tooltip-arrow'));
    };

    Tooltip.prototype.enable = function () {
        this.enabled = true;
    };

    Tooltip.prototype.disable = function () {
        this.enabled = false;
    };

    Tooltip.prototype.toggleEnabled = function () {
        this.enabled = !this.enabled;
    };

    Tooltip.prototype.toggle = function (e) {
        let self = this;
        if (e) {
            self = $(e.currentTarget).data(`bs.${this.type}`);
            if (!self) {
                self = new this.constructor(e.currentTarget, this.getDelegateOptions());
                $(e.currentTarget).data(`bs.${this.type}`, self);
            }
        }

        if (e) {
            self.inState.click = !self.inState.click;
            if (self.isInStateTrue()) self.enter(self);
            else self.leave(self);
        } else {
            self.tip().hasClass('in') ? self.leave(self) : self.enter(self);
        }
    };

    Tooltip.prototype.destroy = function () {
        const that = this;
        clearTimeout(this.timeout);
        this.hide(() => {
            that.$element.off(`.${that.type}`).removeData(`bs.${that.type}`);
            if (that.$tip) {
                that.$tip.detach();
            }
            that.$tip = null;
            that.$arrow = null;
            that.$viewport = null;
        });
    };

    // TOOLTIP PLUGIN DEFINITION
    // =========================

    function Plugin(option) {
        return this.each(function () {
            const $this = $(this);
            let data = $this.data('bs.tooltip');
            const options = typeof option === 'object' && option;

            if (!data && /destroy|hide/.test(option)) return;
            if (!data) $this.data('bs.tooltip', (data = new Tooltip(this, options)));
            if (typeof option === 'string') data[option]();
        });
    }

    const old = $.fn.tooltip;

    $.fn.tooltip = Plugin;
    $.fn.tooltip.Constructor = Tooltip;

    // TOOLTIP NO CONFLICT
    // ===================

    $.fn.tooltip.noConflict = function () {
        $.fn.tooltip = old;
        return this;
    };
})(jQuery);
