import IBAN from 'iban';
require('jquery-validation');

$.validator.methods.number = function (value, element) {
    return this.optional(element) || /^-?(?:\d+|\d{1,3}(?:[\s\.,]\d{3})+)(?:[\.,]\d+)?$/.test(value);
};

$.extend($.validator.messages, {
    required: 'Dit veld is verplicht.',
    email: 'Vul een geldig e-mailadres in.',
    url: 'Vul een geldige URL in, inclusief http://',
    number: 'Vul een getal in.',
    mynumber: 'Vul een getal in.',
    digits: 'Vul alleen cijfers in.',
    accept: 'Kies een bestand van het juiste type.',
    maxlength: $.validator.format('Niet meer dan {0} tekens gebruiken.'),
    minlength: $.validator.format('Gebruik tenminste {0} tekens.'),
    rangelength: $.validator.format('Vul een waarde in met een lengte tussen {0} en {1} tekens.'),
    range: $.validator.format('Vul een waarde in tussen {0} en {1}.'),
    max: $.validator.format('Vul een waarde in van maximaal {0}.'),
    min: $.validator.format('Vul een waarde in van minimaal {0}.'),
    mymin: $.validator.format('Vul een waarde in van minimaal {0}.'),
    alphanumeric: 'Gebruik alleen letters, cijfers, en lage streepjes',
});

$.validator.addMethod(
    'time',
    function (value, element) {
        return this.optional(element) || /(\d{4})-(\d{2})-(\d{2}) (\d{2})\:(\d{2})\:(\d{2})/i.test(value);
    },
    'Vul een geldige datum in, bijvoorbeeld 2016-06-29 14:10:05.',
);

$.validator.addMethod(
    'mynumber',
    function (value, element) {
        return (
            this.optional(element) || /^\d{1,3}([\.]\d{3})*([,]\d\d)?$/i.test(value) || /^\d+([,]\d\d)?$/i.test(value)
        );
    },
    'Vul een geldig getal in, bijvoorbeeld 12.345,67',
);

$.validator.addMethod(
    'mydecimal',
    function (value, element) {
        return this.optional(element) || /^\d{1,3}([\.]\d{3})*([,]\d+)?$/i.test(value) || /^\d+([,]\d+)?$/i.test(value);
    },
    'Vul een geldig getal in, bijvoorbeeld 12.345,6',
);

$.validator.addMethod(
    'zipcode',
    function (value, element) {
        return (
            this.optional(element) ||
            /^[0-9]{4}\s?[a-zA-Z]{2}$/i.test(value) || // dutch zip
            /^[0-9]{4}$/i.test(value) || // belgian zip
            /^[0-9]{5}$/i.test(value)
        ); // german zip
    },
    'Ongeldige postcode',
);

$.validator.addMethod(
    'mymin',
    function (value, element, param) {
        return this.optional(element) || window.parseDecimal(value) >= param;
    },
    'Vul een waarde in van tenminste {0}.',
);

$.validator.addMethod(
    'color',
    function (value, element) {
        return this.optional(element) || (value.length == 7 && value[0] == '#');
    },
    'Vul een kleur in in de vorm #000000.',
);

$.validator.addMethod(
    'iban',
    function (value, element) {
        return this.optional(element) || IBAN.isValid(value);
    },
    'Vul een geldig IBAN in',
);

$.validator.addMethod('customerror', (value, element) => !$(element).hasClass('custom-error'), 'Ongeldige invoer');

$.validator.setDefaults({
    errorPlacement(error, element) {
        if (element.attr('type') == 'hidden') {
            return;
        }

        if (element.parent().hasClass('input-group')) {
            error.insertAfter(element.parent());
        } else if (element.closest('table').length) {
            error.insertAfter(element.closest('table'));
        } else if (element.closest('.validation-group')) {
            error.insertBefore(element.closest('.validation-group'));
        } else {
            error.insertAfter(element);
        }
    },
});

$("form[data-validate='true']").each(function () {
    const self = $(this);
    return self.validate();
});
