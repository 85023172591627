module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var id = _.uniqueId("filerow"); 
__p+='\r\n<a href="'+
((__t=( DownloadUrl ))==null?'':__t)+
'" data-toggle="modal" data-target="#show-file-modal-'+
((__t=(UploadedFileId))==null?'':__t)+
'" class="file-img-container">\r\n	<div class="file-img">\r\n		<img data-selector="thumbnail" src="'+
((__t=( ThumbnailUrl == null ? IconUrl : ThumbnailUrl ))==null?'':__t)+
'"/>\r\n	</div>\r\n</a>\r\n<div class="file-content selectable">\r\n	<input type="hidden" name="UploadedFiles.Index" value="'+
((__t=( id ))==null?'':_.escape(__t))+
'" />\r\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].DownloadUrl" value="'+
((__t=( DownloadUrl ))==null?'':_.escape(__t))+
'" />\r\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].ThumbnailUrl" value="'+
((__t=( ThumbnailUrl ))==null?'':_.escape(__t))+
'" />\r\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].PreviewUrl" value="'+
((__t=( PreviewUrl ))==null?'':_.escape(__t))+
'" />\r\n\r\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].Title" value="'+
((__t=( Title ))==null?'':_.escape(__t))+
'" />\r\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].Description" value="'+
((__t=( Description ))==null?'':_.escape(__t))+
'" />\r\n\r\n	<div class="file-title">\r\n		<a href="'+
((__t=( DownloadUrl ))==null?'':__t)+
'" data-toggle="modal" data-target="#show-file-modal-'+
((__t=(UploadedFileId))==null?'':__t)+
'" class="file-title inline-value" >'+
((__t=( Title ))==null?'':_.escape(__t))+
'</a>\r\n		<input type="text" name="Title" value="'+
((__t=( Title ))==null?'':_.escape(__t))+
'" class="form-control inline-edit" data-inputfor="Title" />\r\n	</div>\r\n	<div class="file-description">\r\n		<span class="inline-value">\r\n			';
 if(Description != null && Description.length > 150) { 
__p+='\r\n				'+
((__t=( Description.substring(0, 147) ))==null?'':_.escape(__t))+
'...\r\n			';
 } else { 
__p+='\r\n				'+
((__t=( Description ))==null?'':_.escape(__t))+
'\r\n			';
 } 
__p+='\r\n		</span>\r\n		<textarea rows="3" class="form-control inline-edit" data-inputfor="Description">'+
((__t=(Description))==null?'':_.escape(__t))+
'</textarea>\r\n	</div>\r\n	<div class="file-actions">\r\n	    ';
 if(!Removing) { 
__p+='\r\n	        ';
 if(Selected) { 
__p+='\r\n                <button class="btn btn-link inline-value" data-selector="select-file-button">deselecteren</button>\r\n	        ';
 } else { 
__p+='\r\n                <button class="btn btn-link inline-value" data-selector="select-file-button">selecteren</button>\r\n	        ';
 } 
__p+='\r\n            <button class="btn btn-link inline-value" data-selector="edit-file-button">bewerken</button>\r\n            <button class="btn btn-link inline-edit" data-selector="save-file-button">opslaan</button>\r\n            <button class="btn btn-link btn-remove inline-value" data-selector="try-delete-file-button">verwijderen</button>\r\n\r\n        ';
 } else { 
__p+='\r\n            <button class="btn btn-link inline-value" data-selector="cancel-delete-file-button">annuleren</button>\r\n            <button class="btn btn-danger inline-value" data-selector="delete-file-button">verwijder</button>\r\n        ';
 } 
__p+='\r\n	</div>\r\n</div>\r\n'+
((__t=( fileModalPartial() ))==null?'':__t)+
'';
}
return __p;
};
