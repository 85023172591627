module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

	/* This modal should probably be kept in sync with the modal in the razor view */

__p+='\r\n\r\n<div class="modal" id="show-file-modal-'+
((__t=(UploadedFileId))==null?'':__t)+
'" tabindex="-1">\r\n	<div class="modal-dialog modal-xl">\r\n		<div class="modal-content">\r\n			<div class="modal-header">\r\n				<button type="button" class="close" data-dismiss="modal">\r\n					<span>&times;</span>\r\n				</button>\r\n				<h4 class="modal-title" id="myModalLabel1">Bestand preview</h4>\r\n			</div>\r\n			<div class="modal-body">\r\n				<div class="row">\r\n					<div class="col-lg-4">\r\n						<img src="'+
((__t=(PreviewUrl))==null?'':__t)+
'" style="max-width: 100%"/>\r\n					</div>\r\n					<div class="col-lg-8">\r\n						<h1>'+
((__t=(Title))==null?'':__t)+
'</h1>\r\n						<p style="white-space: pre-line">\r\n							'+
((__t=( Description ))==null?'':__t)+
'\r\n						</p>\r\n					</div>\r\n				</div>\r\n			</div>\r\n			<div class="modal-footer">\r\n				<button type="button" class="btn btn-cancel" data-dismiss="modal">Annuleren</button>\r\n				<a class="btn btn-download" href="'+
((__t=(DownloadUrl))==null?'':__t)+
'">Downloaden</a>\r\n			</div>\r\n		</div>\r\n	</div>\r\n</div>';
}
return __p;
};
