module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if(IsMe) { 
__p+='\r\n	';
 if(IsDeleted) { 
__p+='\r\n		<div class="message-deleted">\r\n			- Dit bericht is verwijderd door '+
((__t=( AuthorName ))==null?'':_.escape(__t))+
' <span class="message-datetime">'+
((__t=( moment(Date).fromNow() ))==null?'':_.escape(__t))+
'</span>\r\n			';
 if(CanEdit) { 
__p+='\r\n				<button class="btn btn-link" data-selector="undelete-message-button"><i class="icon-arrow-return-left"></i></button>\r\n			';
 } 
__p+='\r\n		</div>\r\n	';
 } else { 
__p+='\r\n		<div class="message-box">\r\n			<div class="message-container">\r\n				<div class="message-content">\r\n					<p class="inline-value pre-line">'+
((__t=( Text ))==null?'':_.escape(__t))+
'</p>\r\n					<textarea rows="3" name="Text" class="form-control inline-edit">'+
((__t=( Text ))==null?'':_.escape(__t))+
'</textarea>\r\n					<div class="message-actions">\r\n						';
 if(CanEdit) { 
__p+='\r\n								<button class="btn btn-link inline-value" data-selector="delete-message-button"><i class="icon-trash-b"></i></button>\r\n								<button class="btn btn-link inline-value" data-selector="edit-message-button"><i class="icon-edit"></i></button>\r\n								<button class="btn btn-link inline-edit" data-selector="save-message-button">opslaan</button>\r\n						';
 } 
__p+='\r\n						';
 if(!IsFlagged) { 
__p+='\r\n							<button class="btn btn-link inline-value" data-selector="flag-message-button"><i class="icon-flag"></i></button>\r\n						';
 } else { 
__p+='\r\n							<button class="btn btn-link inline-value" data-selector="unflag-message-button"><i class="icon-unflag"></i></button>\r\n						';
 } 
__p+='\r\n					</div>\r\n				</div>\r\n				<div class="message-datetime">\r\n					<i title="'+
((__t=( moment(Date).format('D MMM Y, HH:mm') ))==null?'':__t)+
'">'+
((__t=( moment(Date).fromNow() ))==null?'':_.escape(__t))+
'</i>\r\n				</div>\r\n			</div>\r\n			';
 if(SmallAvatarUrl !== null) { 
__p+='\r\n				<div class="message-avatar">\r\n					<img src="'+
((__t=( SmallAvatarUrl ))==null?'':__t)+
'" width="30" height="30" />\r\n				</div>\r\n			';
 } else {
__p+='\r\n				<div class="message-avatar message-avatar-placeholder">\r\n					<img src="'+
((__t=( avatar_placeholder_svg ))==null?'':__t)+
'" alt="" class="avatar-placeholder" width="30" height="30" />\r\n				</div>\r\n			';
 } 
__p+='\r\n		</div>\r\n	';
 } 
__p+='\r\n';
 } else { 
__p+='\r\n	';
 if(IsDeleted) { 
__p+='\r\n		<div class="message-deleted">\r\n			- Bericht verwijderd door '+
((__t=( AuthorName ))==null?'':_.escape(__t))+
' '+
((__t=( moment(Date).fromNow() ))==null?'':_.escape(__t))+
'\r\n			';
 if(CanEdit) { 
__p+='\r\n				<button class="btn btn-undo" data-selector="undelete-message-button"><i class="icon-arrow-return-left"></i></button>\r\n			';
 } 
__p+='\r\n		</div>\r\n	';
 } else { 
__p+='\r\n		<div class="message-box">\r\n			';
 if(SmallAvatarUrl !== null) { 
__p+='\r\n				<div class="message-avatar">\r\n					<img src="'+
((__t=( SmallAvatarUrl ))==null?'':__t)+
'" width="30" height="30" />\r\n				</div>\r\n			';
 } else {
__p+='\r\n				<div class="message-avatar message-avatar-placeholder">\r\n					<img src="'+
((__t=( avatar_placeholder_svg ))==null?'':__t)+
'" alt="" class="avatar-placeholder" width="30" height="30" />\r\n				</div>\r\n			';
 } 
__p+='\r\n\r\n			<div class="message-container">\r\n				<div class="message-content">\r\n					'+
((__t=( AuthorName ))==null?'':_.escape(__t))+
'\r\n					<p class="inline-value pre-line">'+
((__t=( Text ))==null?'':_.escape(__t))+
'</p>\r\n					<textarea rows="3" name="Text" class="form-control inline-edit">'+
((__t=( Text ))==null?'':_.escape(__t))+
'</textarea>\r\n					<div class="message-actions">\r\n						';
 if(CanEdit) { 
__p+='\r\n								<button class="btn btn-link inline-value" data-selector="delete-message-button"><i class="icon-trash-b"></i></button>\r\n								<button class="btn btn-link inline-value" data-selector="edit-message-button"><i class="icon-edit"></i></button>\r\n								<button class="btn btn-link inline-edit" data-selector="save-message-button">opslaan</button>\r\n						';
 } 
__p+='\r\n						';
 if(!IsFlagged) { 
__p+='\r\n							<button class="btn btn-link inline-value" data-selector="flag-message-button"><i class="icon-flag"></i></button>\r\n						';
 } else { 
__p+='\r\n							<button class="btn btn-link inline-value" data-selector="unflag-message-button"><i class="icon-unflag"></i></button>\r\n						';
 } 
__p+='\r\n					</div>\r\n				</div>\r\n				<div class="message-datetime">\r\n					<i title="'+
((__t=( moment(Date).format('D MMM Y, HH:mm') ))==null?'':__t)+
'">'+
((__t=( moment(Date).fromNow() ))==null?'':_.escape(__t))+
'</i>\r\n				</div>\r\n			</div>\r\n		</div>\r\n	';
 } 
__p+='\r\n';
 } 
__p+='';
}
return __p;
};
